@font-face {
    font-family: "Graphik";
    src: url("../assets/fonts/Graphik-Regular.woff2") format("woff2"),
        url("../assets/fonts/Graphik-Regular.woff") format("woff"),
        url("../assets/fonts/Graphik-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Graphik";
    src: url("../assets/fonts/Graphik-Bold.woff2") format("woff2"),
        url("../assets/fonts/Graphik-Bold.woff") format("woff"),
        url("../assets/fonts/Graphik-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Roboto";
    src: url("../assets/fonts/Roboto-Regular.woff2") format("woff2"),
        url("../assets/fonts/Roboto-Regular.woff") format("woff"),
        url("../assets/fonts/Roboto-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Roboto";
    src: url("../assets/fonts/Roboto-Bold.woff2") format("woff2"),
        url("../assets/fonts/Roboto-Bold.woff") format("woff"),
        url("../assets/fonts/Roboto-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Montserrat";
    src: url("../assets/fonts/Montserrat-Bold.woff2") format("woff2"),
        url("../assets/fonts/Montserrat-Bold.woff") format("woff"),
        url("../assets/fonts/Montserrat-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
