$xxl-bp: 1440px;
$xl-bp: 1200px;
$tablet-bp: 991px;
$mobile-bp: 575px;

:root {
    /* Fonts and typography */
    --main-font: "Graphik", sans-serif;
    --sub-font: "Roboto", sans-serif;
    --logo-font: "Montserrat", sans-serif;

    --h1-h2-font-size: 6.4rem;
    --h3-font-size: 4.8rem;
    --authorization-title: 3.6rem;
    --biggest-font-size: 2.4rem;
    --bigger-font-size: 2rem;
    --regular-font-size: 1.6rem;
    --smaller-font-size: 1.4rem;
    --smallest-font-size: 1.3rem;

    /* Font weight */
    --font-regular: 400;
    --font-bold: 700;

    /* Colors */

    /* Main */
    --theme-color: #c68e24;

    /* Error */
    --error-color: #ec1d1d;

    /* Backgrounds */
    --bg-color: #171717;
    --card-bg: #242424;
    --input-bg: #333333;

    /* Regular colors */
    --black: #121212;
    --white: #ffffff;
    --grey: #787878;
    --grey-sub: #999999;
    --red: #ff4242;

    /* Card font colors */
    --card-font-1: #c68e24;
    --card-font-2: #eceab2;
    --card-font-3: #4ebb87;
    --card-font-4: #47b5cd;

    /* Card font background */
    --card-bg-1: linear-gradient(225deg, #2b2b2b 0%, #121212 100%);
    --card-bg-2: linear-gradient(225deg, #841a1a 0%, #121212 100%);
    --card-bg-3: linear-gradient(225deg, #42087d 0%, #121212 100%);
    --card-bg-4: linear-gradient(225deg, #2d51d2 0%, #121212 100%);

    /* Card information font colors */
    --card-information-1: #999999;
    --card-information-2: #e6e6eb;
    --card-information-3: #fcdcff;
    --card-information-4: #c8f2ff;

    /* Z-indexes */
    --burger-menu-index: 900;
    --nav-index: 850;
}

@media screen and (max-width: 991px) {
    :root {
        --h1-h2-font-size: 3.6rem;
        --h3-font-size: 3.2rem;
        --authorization-title: 2.4rem;
        --biggest-font-size: 2rem;
        --bigger-font-size: 1.8rem;
        --regular-font-size: 1.6rem;
        --smaller-font-size: 1.4rem;
        --smallest-font-size: 1.3rem;
    }
}

@media screen and (max-width: 375px) {
    :root {
        --h1-h2-font-size: 3.2rem;
        --h3-font-size: 2.8rem;
        --authorization-title: 2.2rem;
        --biggest-font-size: 1.8rem;
        --bigger-font-size: 1.6rem;
        --regular-font-size: 1.4rem;
        --smaller-font-size: 1.3rem;
        --smallest-font-size: 1.1rem;
    }
}
