.loader {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 100;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 300px;
    height: 200px;
    transform: translate(-50%, -50%);

    .bar {
        width: 12px;
        height: 2px;
        background-color: white;
        border-radius: 1rem;
        animation: up-down 1.5s ease-in-out infinite;

        &:nth-child(1) {
            background-color: var(--card-font-4);
            animation-delay: 1s;
        }

        &:nth-child(2) {
            background-color: var(--card-font-2);
            animation-delay: 0.8s;
        }

        &:nth-child(3) {
            background-color: var(--card-font-3);
            animation-delay: 0.6s;
        }

        &:nth-child(4) {
            background-color: gold;
            animation-delay: 0.4s;
        }

        &:nth-child(5) {
            background-color: var(--theme-color);
            animation-delay: 0.2s;
        }

        &:nth-child(6) {
            background-color: var(--theme-color);
            animation-delay: 0.2s;
        }

        &:nth-child(7) {
            background-color: gold;
            animation-delay: 0.4s;
        }

        &:nth-child(8) {
            background-color: var(--card-font-3);
            animation-delay: 0.6s;
        }

        &:nth-child(9) {
            background-color: var(--card-font-2);
            animation-delay: 0.8s;
        }

        &:nth-child(10) {
            background-color: var(--card-font-4);
            animation-delay: 1s;
        }
    }

    &--small {
        position: relative;
        top: auto;
        left: auto;
        width: 150px;
        height: 30px;
        transform: translate(0);

        .bar {
            animation: up-down-small 1.5s ease-in-out infinite;
        }
    }
}

@keyframes up-down {
    0%,
    100% {
        height: 2px;
    }

    50% {
        height: 80px;
    }
}

@keyframes up-down-small {
    0%,
    100% {
        height: 2px;
    }

    50% {
        height: 50px;
    }
}
