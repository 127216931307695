.nav {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    background-color: var(--black);
    filter: drop-shadow(0 4px 20px rgba(0, 0, 0, 0.25));
}

.logo {
    font-family: var(--logo-font);
    font-size: var(--h3-font-size);
    font-weight: var(--font-bold);
    line-height: 1;
    color: var(--white);
}

.nav__container {
    max-width: 1150px;
    padding: 2rem;
    margin: 0 auto;

    &--open {
        .nav-burger {
            .nav-burger__line-1 {
                bottom: 10px;
                transform: rotate(45deg);
            }

            .nav-burger__line-2 {
                opacity: 0;
            }

            .nav-burger__line-3 {
                bottom: 10px;
                transform: rotate(-45deg);
            }
        }

        .nav__links {
            pointer-events: all;
            visibility: visible;
            opacity: 1;
        }
    }
}

.nav__item {
    margin-right: 3rem;
}

.nav__link {
    font-size: var(--smaller-font-size);
    font-weight: var(--font-regular);
    line-height: 1;
    color: var(--white);
    transition: 0.3s ease-in-out;

    &--active {
        font-weight: var(--font-bold);
    }

    &--orange {
        color: var(--theme-color);
    }

    &:hover {
        color: var(--theme-color);
    }
}

.nav__log {
    @extend .nav__link;

    align-items: center;
    justify-content: center;
    padding: 0.5rem 2.5rem;
    font-weight: var(--font-bold);
    line-height: 2.1rem;
    text-align: center;
    border: 1px solid var(--white);
    border-radius: 5rem;

    &:hover {
        color: var(--white);
        border-color: var(--theme-color);
    }

    &.active {
        border-color: var(--theme-color);
    }
}

.nav-burger__container {
    .btn {
        margin-right: 1rem;
    }
}

.nav-burger {
    position: relative;
    z-index: var(--burger-menu-index);
    width: 20px;
    height: 12px;
    margin-left: auto;
}

.nav-burger__line {
    position: absolute;
    display: block;
    width: 100%;
    height: 2px;
    background-color: var(--white);
    border-radius: 5rem;
    transition: 0.3s ease;

    &.nav-burger__line-2 {
        top: 5px;
    }

    &.nav-burger__line-3 {
        bottom: 0;
    }
}

@media screen and (max-width: 991px) {
    .logo {
        font-size: 1.8rem;
    }

    .nav__container {
        position: relative;
    }

    .nav__links {
        position: absolute;
        top: 0;
        right: 0;
        z-index: var(--nav-index);
        flex-direction: column;
        align-items: center;
        width: 190px;
        min-height: 100vh;
        padding-top: 8rem;
        margin-right: -8px;
        pointer-events: none;
        visibility: hidden;
        background-color: var(--bg-color);
        border-radius: 1rem 0 0 1rem;
        box-shadow: 4px 8px 12px 0 #0003;
        opacity: 0;
        transition: 0.3s ease-in-out;

        .nav__log {
            margin-top: 2rem;
        }
    }

    .nav__list {
        display: grid;
        gap: 2rem;
        justify-items: center;
    }

    .nav__item {
        margin-right: 0;
    }
}
