.Toastify__toast-theme--dark {
    background: var(--card-bg) !important;
}

.main {
    margin-top: 11rem;
}

@media screen and (max-width: 996px) {
    .main {
        margin-top: 7rem;
    }
}