.btn {
    display: inline-flex;
    align-items: center;
    align-self: flex-start;
    justify-content: center;
    min-width: 150px;
    padding: 1rem 2rem;
    font-size: var(--regular-font-size);
    font-weight: var(--font-bold);
    line-height: 1.2;
    color: var(--white);
    text-align: center;
    border: 1px solid var(--white);
    border-radius: 5rem;
    transition: 0.3s ease-in-out;

    &:hover {
        color: var(--theme-color);
    }

    &--gold {
        border-color: var(--theme-color);
    }

    &--small {
        flex: 0 1 90px;
        padding: 0.4rem 2rem;
        font-size: var(--smaller-font-size);
    }
}
