@import "vars";
@import "mixins";
@import "fonts";
@import "utils";
@import "base";

.embed {
    position: relative;
    display: block;
    width: 100%;
    max-width: 430px;
    margin: 0 auto;
    overflow: hidden;

    &::before {
        display: block;
        padding-top: 56.25%;
        content: "";
    }

    iframe,
    video,
    object,
    embed {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        border: none;
    }
}
